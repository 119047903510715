<template>
  <div>
    <p>Location</p>
    <locationDrop class="locationDropDisp" :startingId="'0'" :states="locationStates" :enabled="locationDropDownEnabled"
                  @selection="locationSelected"></locationDrop>
  </div>

  <div>
    <p>Vehicle</p>
    <vehicleDrop class="vehicleDropDisp" :startingId="'0'" :states="vehicleStates" :enabled="vehicleDropDownEnabled"
                 @selection="vehicleSelected"></vehicleDrop>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, toRefs} from 'vue';
import LocationDrop from '../../components/dropdowns/dropdown1.vue';
import VehicleDrop from '../../components/dropdowns/dropdown1.vue';

const props = defineProps({src: String});
const {src} = toRefs(props);
const emit = defineEmits(['selected']);

console.log("VTYPE", src);

let currentDropletId = "";
let locationDropDownEnabled = ref(false);
let vehicleDropDownEnabled = ref(false);

let locationStates = ref({});
let vehicleStates = ref({});

const getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
let getVehicleUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&type=" + src.value + "&location=";

fetch(getLocationsUrl, {
  headers: {'Content-type': 'application/json'},
}).then((res) => res.json()).then((response) => {
  locationStates.value = response;
  locationDropDownEnabled.value = true;
}).catch((error) => {
  console.log("LOCATION FETCH ERROR " + error);
});

let currentlySelectedLocationId = "";

function locationSelected(id) {
  vehicleStates.value = {};
  currentlySelectedLocationId = id;
  fetch(getVehicleUrl + id, {
    headers: {'Content-type': 'application/json'},
  }).then((res) => res.json()).then((response) => {
    vehicleStates.value = response;
    vehicleDropDownEnabled.value = true;
  }).catch((error) => {
    console.log("VEHICLE FETCH ERROR " + error);
  });
}

function vehicleSelected(id) {
  currentDropletId = id;
  emit('selected', {
        "droplet": currentDropletId,
        "location": locationStates.value[currentlySelectedLocationId]
      }
  );

}
</script>

<style>
</style>